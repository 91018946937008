/**
 * This is a universal utility for site tracking plans. It leverages Google Tag Manager and integrates with OneTrust
 * consent manager for GDPR compliance. Provides a facade for the methods of the Segment SDK and dispatches those as
 * data layer events which Segment can run natively via custom GTM tag if appropriate level of consent has been granted.
 * Usage:
 * <script>
 *  window.disw.init({
 *    gtm: {
 *      gtmId: 'your Google Tag Manager id',
 *      cookeBannerEnabled: true | false (default: true),
 *      cookieBannerSettingsId: 'your UserCentrics settings id' (default: 'GXj83XOdm'),
 *      cookieBannerVersion: 'preview' | 'latest' (default: 'latest'),
 *      cookieMonsterHost: 'w3' | 'wwwstage' (default 'w3')
 *    }
 *  });
 * </script>
 **/

import { v4 as uuidv4 } from 'uuid';
import { saveCampaignSession } from './campaignTracking';

// Initialize window.dataLayer immediately, preserving window.datalayer if it already exists
window.dataLayer = window.dataLayer || [];

export const pusher = payload => {
  if (window.gtmLoaded) {
    window.dataLayer.push(payload);
  } else {
    window.gtmPreLoadDataLayer.push(payload);
  }
};
// Adding support for the segment implementation
// This will be supported going forward, but is considered legacy
// We do not initalize window.analytics here because of the V1 track implemenation
export const analytics = {
  identify: (userId, userProperties) => {
    pusher({ userProperties: undefined });
    pusher({ event: 'identify', userId, userProperties, hitId: uuidv4() });
  },
  page: (pageCategory, pageName, pageProperties) => {
    pusher({ pageProperties: undefined });
    pusher({ event: 'page', pageCategory, pageName, pageProperties, hitId: uuidv4() });
  },
  track: (eventName, eventProperties) => {
    pusher({ eventProperties: undefined });
    pusher({ event: 'track', eventName, eventProperties, hitId: uuidv4() });
  }
};

/**
 * Load the external GTM javascript configured for our sites
 * @param {string} gtmId Google Tag Manager ID
 */
export function loadGtmModule(gtmId) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://g.sw.siemens.com/a0fe7e389dc0e3bf.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmId);
}

function appendChildToBody(element) {
  if (document.body) {
    document.body.appendChild(element);
  } else {
    const bodyLoadedObserver = new MutationObserver(() => {
      if (document.body) {
        document.body.appendChild(element);
        bodyLoadedObserver.disconnect();
      }
    });
    bodyLoadedObserver.observe(document.documentElement, { childList: true });
  }
}

export function loadCookieBanner(version, settingsId) {
  const script = document.createElement('script');
  script.id = 'usercentrics-cmp';
  script.setAttribute('data-version', version);
  script.setAttribute('data-settings-id', settingsId);
  script.src = `https://data.cdn.siemens.com/?settingsId=${settingsId}`;
  script.async = true;
  appendChildToBody(script);
}

export function loadCookieMonster(host) {
  // Create a callback function that will be invoked when the CookieMonster script is loaded in the DOM
  const registerCookieMonsterListeners = function () {
    if (window.cookieMonster) {
      // targeting cookies allowed
      window.cookieMonster.once('targ', () => {
        pusher({ ad_storage: true });
        pusher({ personalization_storage: true });
      });
      // functional cookies allowed
      window.cookieMonster.once('fnct', () => {
        pusher({ functionality_storage: true });
      });
      // performance cookies allowed
      window.cookieMonster.once('perf', () => {
        pusher({ analytics_storage: true });
      });
      // required cookies allowed
      window.cookieMonster.once('reqd', () => {
        pusher({ security_storage: true });
      });
    }
  };
  const script = document.createElement('script');
  script.src = `https://${host}.siemens.com/ste/cookiemonster/cookiemonster.js`;
  script.async = true;
  script.addEventListener('load', registerCookieMonsterListeners);
  appendChildToBody(script);
}

export function loadCookieConsent(config) {
  loadCookieBanner(config.cookieBannerVersion, config.cookieBannerSettingsId);
  loadCookieMonster(config.cookieMonsterHost);
}

export const gtmLoader = (account, config, callback) => {
  if (account && account.sid) {
    if (!window.gtmLoaded) {
      // Create campaign session only on first gtmLoader call
      saveCampaignSession({ sessionId: account.sid, userId: account.user?.contact_id });
      window.dataLayer.push({ sessionId: account.sid });
    }
    // allow userId to be pushed after GTM Load because anonymous users can be assigned
    // a contact_id over the course of a session
    if (account.user && account.user.contact_id) {
      window.dataLayer.push({ userId: account.user.contact_id });
    }

    if (!window.gtmLoaded) {
      window.dataLayer.push({ initialPageHitId: uuidv4() });
      callback(config.gtmId);
      window.gtmLoaded = true;
      window.dataLayer.push(...window.gtmPreLoadDataLayer);
    }
  }
};

/**
 * Initialize GTM (Google Tag Manager)
 * @param {object} config config.gtm from disw.init
 * @param {string} config.gtmId GTM container id
 * @param {string | undefined} config.pageId The contentful page entry ID
 * @param {boolean} [config.cookieBannerEnabled=true] whether to enable the cookie banner (true or false)
 * @param {string} [config.cookieBannerSettingsId=GXj83XOdm] Siemens UserCentrics settings id (override)
 * @param {string} [config.cookieBannerVersion=latest] version tag of Siemens AG cookie banner to load (latest or preview)
 * @param {string} [config.cookieMonsterHost=w3] hostname to load Siemens AG cookiemonster library from (w3 or wwwstage)
 * @param {function} callback optional callback used for initializing GTM. a default callback is used
 */
export async function initializeGtm(config = {}, callback = loadGtmModule) {
  /* Initialize config variable and apply default values */
  // eslint-disable-next-line no-prototype-builtins
  if (!config.hasOwnProperty('gtmId')) {
    throw new Error('config.gtmId is required');
  }
  config.cookieBannerEnabled = config.cookieBannerEnabled ?? true;
  config.cookieBannerSettingsId = config.cookieBannerSettingsId || 'GXj83XOdm';
  config.cookieBannerVersion = config.cookieBannerVersion || 'latest';
  config.cookieMonsterHost = config.cookieMonsterHost || 'w3';

  /* Initialize variable to prevent GTM from being loaded each time an account event is emitted.
   * sid does not change so we don't want to push it on the dataLayer each time an event is emitted.
   * contact_id can change in the case where an anonymous user is assigned a contact_id in the session.
   */
  window.gtmLoaded = false;

  // Put page, identify, and track calls here until session and contact_id are pushed and GTM is loaded
  window.gtmPreLoadDataLayer = [];

  // Initialize a lightweight analytics object that manages legacy segment methods
  window.analytics = analytics;

  // Configure GTM
  window.dataLayer.push(['config', config.gtmId, { send_page_view: false, client_storage: 'none' }]);
  window.dataLayer.push(['js', new Date()]);
  // If we have a pageId, push it to the data layer
  if (typeof config.pageId !== 'undefined') {
    window.dataLayer.push({ pageId: config.pageId });
    window.analytics.pageId = config.pageId;
  }

  // Load consent framework (UserCentrics + CookieMonster)
  if (config.cookieBannerEnabled) {
    loadCookieConsent(config);
  }

  // Listen for disw.account to retrieve the session
  // Only after we get a sessionId can we load GTM
  // eslint-disable-next-line no-undef
  disw.account.subscribeToAccount(account => gtmLoader(account, config, callback));
}
